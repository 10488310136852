import GammaVegaLogo from "./static/img/gammaVegaLogo.png"
import Logo from "./static/img/logo.png"
import OHLogo from "./static/img/OHLogo.png"

export const PRIMARY = 'rgba(0, 94, 137, 1)' //'rgba(0, 119, 200, 1)'//'rgba(0, 51, 102, 1)'
export const PRIMARY_BACKGROUND = 'rgba(0, 94, 137, 0.1)' //'rgba(0, 119, 200, 0.1)'//'rgba(0, 51, 102, 0.1)'
export const PRIMARY_BACKGROUND_DARK = 'rgba(0, 94, 137, 0.3)'
export const PRIMARY_TRANSPARENT = 'rgba(0, 94, 137, 0.0)'

export const SECONDARY = 'rgba(215, 108, 57, 1)' //'rgba(66, 66, 66, 1)'//'rgba(124, 193, 65, 1)'
export const SECONDARY_BACKGROUND = 'rgba(215, 108, 57, 0.1)' //'rgba(66, 66, 66, 0.1)'//'rgba(124, 193, 65, 0.1)'
export const SECONDARY_BACKGROUND_DARK = 'rgba(215, 108, 57, 0.3)'//'rgba(124, 193, 65, 0.1)'



export const COMPANY_LOGO = Logo
export const OH_LOGO = OHLogo
